<template>
    <div class="module">
        <van-nav-bar title="报警管理" left-arrow  @click-left="$router.go(-1)" />
        <div class="alarm-content">
            <div v-for="(item,index) in data" :key="index" :class="item.status ? 'block' : 'block dispose'">
                <div :class="{'bg-content':true,'bg-dispose-img':item.status}">
                    <div><span>告警设备：</span>{{item.devName}}</div>
                    <div><span>报警地点：</span>{{item.address}}</div>
                    <div><span>告警类型：</span>{{`${item.alarmType}--${item.type}`}}</div>
                    <div><span>报警级别：</span>
                        <van-tag size="medium" type="warning" v-if="item.level === 0">普通</van-tag>
                        <van-tag size="medium" type="danger" v-else>紧急</van-tag>
                    </div>
                    <div><span>告警时间：</span>{{item.alarmTime}}</div>
                    <div v-if="item.status">
                        <van-divider />
                        <div class="btn">
                            <van-button type="primary" size="small" @click="dispose(item)">去处理</van-button>
                        </div>
                    </div>
                    <div v-else>
                        <div><span>操作员：</span>{{item.admin}}</div>
                        <div><span>处理时间：</span>{{item.disposeTime}}</div>
                    </div>
                </div>
            </div>
        </div>
        <van-overlay :show="overlayShow">
            <div class="wrapper-loading">
               <van-loading color="#1989fa" />
            </div>
        </van-overlay>
    </div>
</template>

<script>
Date.prototype.Format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, // 月份
        "d+": this.getDate(), // 日
        "h+": this.getHours(), // 小时
        "m+": this.getMinutes(), // 分
        "s+": this.getSeconds(), // 秒
        "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
        "S": this.getMilliseconds() // 毫秒
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;
}
export default {
    data(){
        return{
            data:[
                {id:1,devName:'出京11 CK77+867',address:'K77+867',alarmType:'视频告警',type:'停车',level:1,status:1,alarmTime:'2021-10-26 15:17:14'},
                {id:2,devName:'出京停4 CK79+325',address:'K79+325',alarmType:'视频告警',type:'抛撒物',level:0,status:1,alarmTime:'2021-10-22 11:48:34'},
                {id:3,devName:'出京停4 CK79+325',address:'K79+325',alarmType:'视频告警',type:'抛撒物',level:1,status:1,alarmTime:'2021-10-22 11:44:10'},
                {id:4,devName:'出京停4 CK79+325',address:'K79+325',alarmType:'视频告警',type:'抛撒物',level:1,status:0,alarmTime:'2021-10-22 11:22:56',admin:'管理员',disposeTime:'2021-10-24 09:43:23'},
                {id:5,devName:'进京1 K79+798',address:'K79+798',alarmType:'视频告警',type:'行人',level:1,status:0,alarmTime:'2021-10-22 11:19:38',admin:'管理员',disposeTime:'2021-10-24 09:30:02'},
                {id:6,devName:'进京2 K79+650',address:'K79+650',alarmType:'视频告警',type:'行人',level:0,status:0,alarmTime:'2021-10-22 11:17:30',admin:'管理员',disposeTime:'2021-10-24 09:28:36'},
                {id:7,devName:'进京3 K79+500',address:'K79+500',alarmType:'视频告警',type:'行人',level:1,status:0,alarmTime:'2021-10-22 11:15:24',admin:'管理员',disposeTime:'2021-10-24 09:24:51'},
                {id:8,devName:'进京3 K79+500',address:'K79+500',alarmType:'视频告警',type:'行人',level:1,status:0,alarmTime:'2021-10-22 11:15:20',admin:'管理员',disposeTime:'2021-10-24 09:23:45'},
                {id:9,devName:'进京4 K79+350',address:'K79+350',alarmType:'视频告警',type:'行人',level:0,status:0,alarmTime:'2021-10-22 11:13:35',admin:'管理员',disposeTime:'2021-10-24 09:20:12'},
                {id:10,devName:'进京4 K79+350',address:'K79+350',alarmType:'视频告警',type:'行人',level:1,status:0,alarmTime:'2021-10-22 11:13:10',admin:'管理员',disposeTime:'2021-10-24 09:18:03'}
            ],
            overlayShow:false
        }
    },
    methods:{
        dispose(item){
            this.$dialog.confirm({
                message:'确定报警内容已处理完毕, 是否继续?',
            })
            .then(() => {
                this.overlayShow = true
                this.$toast({
                    message: '处理成功',
                    position: 'bottom',
                });
                setTimeout(()=>{
                    this.overlayShow = false;
                    this.data = this.data.map(v =>
                        ({
                            ...v,
                            status: v.id === item.id ? !v.status : v.status,
                            admin:v.id === item.id ? '管理员' : v.admin,
                            disposeTime:  v.id === item.id  ? new Date().Format("yyyy-MM-dd hh:mm:ss") : v.disposeTime
                        })
                    ).sort((a,b) => new Date(b.disposeTime) - new Date(a.disposeTime)  // 根据处理日期降序
                    ).sort((a,b) => b.status - a.status) // 根据状态降序
                },1000)
            })
            .catch(() => {})
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/tunnel/module.scss';
</style>